
import React, { Component } from 'react';
import './App.scss'
import { Button, Fab, Link } from '@material-ui/core';
import { KeyboardArrowDown, Phone, PhoneAndroid, EmailOutlined, Person, LocationOn } from '@material-ui/icons/';
import Card from './components/Card/Card';
import IconList, { IconListItem } from './components/IconList/IconsList';
import ContactForm from './components/ContactForm';
import Navbar, { toggleGalleryPublic } from './components/Navbar/Navbar';

import iconTractor from './components/icons/tractor1.svg'
import iconCar from './components/icons/car1.svg'
import iconMoto from './components/icons/moto1.svg'

class App extends Component {

  openGallery() {
    toggleGalleryPublic();
  }
  render() {
    console.log(iconMoto);
    return (
      <div>
        <Navbar />
        <div id="landing-page">

          <div className="tittle">
            <h1>Autoškola&nbsp;ELko</h1>
            <h2>Pomůžeme Vám <br /> stát se řidičem</h2>
            <Button variant="contained" href="#services" color="primary">Zajímá mě to</Button>
          </div>
          <div className="icon-holder">
            <div className="icons">
              <img src={iconTractor} id="iconTractor" alt="tractor" />
              <img src={iconMoto} id="iconMoto" alt="motocycle" />
              <img src={iconCar} id="iconCar" alt="car" />
            </div>
          </div>
          <Fab color="primary" className="icon-tittle" href="#about-us-services">
            <KeyboardArrowDown />
          </Fab>

        </div>

        <div id="about-us-services">
          <span id="about-us"></span>
          <div className="section" >
            <h2 ><span className="half-bg-holder"> o nás<div className="half-bg"></div></span></h2>
            <p>Provádíme výuku a výcvik v řízení motorových vozidel všech skupin. Naším cílem je připravit naše žáky na úspěšné složení závěrečné zkoušky po absolvování všech cvičných hodin.</p>
            <p>Naše společnost, ELKO autoškola spol. s. r. o., vznikla 1. 7. 1996 jako nástupce Autoškoly Svazarmu.</p>
            <p>Od 1. 1. 2007 sídlíme ve <strong> 2. patře nově zrekonstruované budovy firmy Pyrotek, na adrese Pražská 5, Blansko.</strong></p>
            <p>Naši druhou provozovnu najdete v Boskovicích na ulici <strong>Hybešova č. 17, naproti prodejně Citroen</strong></p>
            <Button className="contact-button" size="large" variant="contained" color="primary" href="#contacts">Kontakt</Button>
          </div>
          <span id="services"></span>
          <div className="section" >
            <h2><span className="half-bg-holder">služby<div className="half-bg"></div></span></h2>
            <div className="services-list">
              <div className="big-number">1.</div>
              <div className="list-text">Provádíme výuku a výcvik v řízení motorových vozidel všech skupin. Naší snahou je, abychom žáky dobře připravily na úspěšné složení závěrečné zkoušky po absolvování všech cvičných hodin.</div>
            </div>
            <div className="services-list">
              <div className="big-number">2.</div>
              <div >
                <ul className="conected-list">
                  <li>
                    <div className="bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false"><circle stroke="none" cx="16" cy="16" r="10"></circle></svg>
                    </div>
                    Pravidelné školení řidičů profesionálů – 7 hodin.
                  </li>
                  <li>
                    <div className="bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false"><circle stroke="none" cx="16" cy="16" r="10"></circle></svg>
                    </div>
                    Školení řidičů referentských vozidel.
                  </li>
                  <li>
                    <div className="bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false"><circle stroke="none" cx="16" cy="16" r="10"></circle></svg>
                    </div>
                    Výuku a výcvik k získání profesního průkazu skupin C, C+E, D v rozsahu 35 nebo 140 hodin.
                  </li>
                  <li>
                    <div className="bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false"><circle stroke="none" cx="16" cy="16" r="10"></circle></svg>
                    </div>
                    Autobusová doprava
                  </li>
                </ul>
              </div>
            </div>
            <div className="services-list">
              <div className="big-number">3.</div>
              <div className="list-text">
                Kondiční jízdy
              </div>
            </div>
          </div>
        </div>

        {/* garage */}
        <div className="garage-section">
          <div className="holder">
            <h2>Co máme<br />v garáži?</h2>
            <div className="text">
              <p>Zajímalo by Vás, v čem se bude výuka odehrávat?</p>
              <br />
              <b>Podívejte se do naší galerie!</b>
            </div>
            <div className="image">
              <div className="button-holder">
                <Button variant="contained" color="primary" size="large" onClick={this.openGallery.bind(this)}>Galerie</Button>
              </div>
            </div>
          </div>
        </div>

        {/* cards */}
        <div className="card-holder">
          <Card
            icon={require('./components/icons/moto2.svg').default}
            headline="MOTO">
            <table>
              <tbody>
                <tr >
                  <td className="left-part">AM</td>
                  <td> 15+</td>
                  <td> 13&nbsp;500,-</td>
                </tr>
                <tr>
                  <td className="left-part">A1</td>
                  <td> 16+ </td>
                  <td> 13&nbsp;500,-</td>
                </tr>
                <tr>
                  <td className="left-part">A2</td>
                  <td> 18+ </td>
                  <td> 13&nbsp;500,-</td>
                </tr>
                <tr>
                  <td className="left-part">A </td>
                  <td> 24+<span>(1)</span></td>
                  <td> 13&nbsp;500,-</td>
                </tr>
              </tbody>
            </table>
            <span className="note"> (1) nebo 2 roky praxe skupiny A2</span>
          </Card>

          <Card
            icon={require('./components/icons/car2.svg').default}
            headline="OSOBNÍ AUTOMOBIL">
            <p> Vozidlo pro max. 9 osob nebo vozidlo do 3,5 t. </p>
            <table>
              <tbody>
                <tr >
                  <td className="left-part">B</td>
                  <td> 18+</td>
                  <td> 17&nbsp;500,-</td>
                </tr>
                <tr>
                  <td className="left-part">B&nbsp;+&nbsp;E<span>(2)</span></td>
                  <td> 18+</td>
                  <td> 6&nbsp;500,-</td>
                </tr>
                <tr>
                  <td className="left-part">B96<span>(3)</span></td>
                  <td> 18+</td>
                  <td> 4&nbsp;000,-</td>
                </tr>
              </tbody>
            </table>
            <span className="note">
              (2) souprava do 7000kg <br />
              (3) souprava do 4250 kg
            </span>
          </Card>

          <Card
            icon={require('./components/icons/truck.svg').default}
            headline="NÁKLADNÍ AUTOMOBIL">
            <table>
              <tbody>
                {<tr >
                  <td className="left-part">C</td>
                  <td>21+</td>
                  <td> 17&nbsp;500,-</td>
                </tr>}
                <tr>
                  <td className="left-part">C&nbsp;+&nbsp;E<span>(4)</span></td>
                  <td> 21+</td>
                  <td> 8&nbsp;000,-</td>
                </tr>
              </tbody>
            </table>
            <span className="note">
              (4) nákladní automobil + přívěs
            </span>
          </Card>

          <Card
            icon={require('./components/icons/bus.svg').default}
            headline="AUTOBUS">
            <table>
              <tbody>
                {/* <tr >
                  <td className="left-part">D</td>
                  <td> 24+</td>
                  <td> 20&nbsp;000,-</td>
                </tr> */}
                <tr >
                  <td className="left-part">D (z C)</td>
                  <td> 24+</td>
                  <td> 18&nbsp;000,-</td>
                </tr>
                <tr >
                  <td className="left-part">D (z B)</td>
                  <td> 24+</td>
                  <td> 26&nbsp;000,-</td>
                </tr>
              </tbody>
            </table>
          </Card>

          <Card
            icon={require('./components/icons/tractor2.svg').default}
            headline="TRAKTOR">
            <table>
              <tbody>
                <tr >
                  <td className="left-part">T<span>(5)</span></td>
                  <td> 17+</td>
                  <td> 9&nbsp;000,-</td>
                </tr>
              </tbody>
            </table>
            <span className="note">
              (5) rozšíření na T + 7 000,-
            </span>
          </Card>
        </div>
        {<div className="discount" >
          <div className="text" >
            <h3> Kompletní ceník ke stažení zde: </h3><br />
            <Link
              href={require("./components/files/cenik.pdf")}
              download={"cenik_cely.pdf"}>
              PDF</Link> <br />
          </div>
        </div>}

        <span id="scrollto-information"></span>
        <div id="information">
          <h2 className="heading"><span className="half-bg-holder">Informace<div className="half-bg"></div></span></h2>
          <div className="icon-list-holder">
            <IconList
              data={[
                new IconListItem("Získání Žádosti o získání řidičského oprávnění", require('./components/icons/question.svg').default, "Osobně v kancelářích autoškoly, mimo pracovní dobu na informační tabuli u budovy Pyrotek (kancelář Blansko) nebo ve schránce na dveřích kanceláře Boskovice. ", [{ link: require("./components/files/1rubrika.pdf"), name: "zadost_o_prijeti_k_vyuce.pdf", text: "Ke stažení zde" }]),
                new IconListItem("Vyplnění Žádosti o získání řidičského oprávnění", require('./components/icons/writting.svg').default, "Vyplněné údaje musí souhlasit s údaji v občanském průkazu. Nezapomeňte vyplnit Váš telefonní kontakt, abychom Vás mohli, v případě potřeby, kdykoli kontaktovat. Žádost uchazeče mladšího 18-ti let musí být doplněna souhlasem zákonného zástupce o přijetí do výuky a výcviku."),
                new IconListItem("Přílohy k přihlášce", require('./components/icons/doctor.svg').default, "Lékařská prohlídka (ne starší 3 měsíců). ", [{ link: require("./components/files/3rubrika.pdf"), name: "lekraska_zprava.pdf", text: "Lekářská zpráva," }, { link: require("./components/files/3rubrika2.pdf"), name: "gdpr.pdf", text: " GDPR" }]),
                new IconListItem("Odevzdání Žádosti o získání řidičského oprávnění", require('./components/icons/lettter.svg').default, "Kompletně vyplněnou a podepsanou přihlášku odevzdejte v kanceláři autoškoly nebo ji můžete zanechat ve schránce na informační tabuli pro kancelář Blansko nebo ve schránce kanceláře Boskovice."),
                new IconListItem("Zahájení výcviku a platba", require('./components/icons/deal.svg').default, "Po obdržení Žádosti o získání řidičského oprávnění Vás budeme kontaktovat a domluvíme s Vámi podrobnosti o zahájení výcviku a platbě a její konkrétní výši."),
                new IconListItem("Výuka", require('./components/icons/lecture.svg').default, "Výcvik v autoškole se skládá z teoretické a praktické přípravy. Jak výuku teorie, tak jízdy, provádějí zkušení instruktoři s dlouholetou praxí. Termíny výuky i jízd přizpůsobíme Vašim možnostem, po předchozí dohodě je možné zahájit i ukončit jízdu i v místě Vašeho bydliště. Praktická příprava je rozdělena do tří etap. První 2 jízdy se konají na izolovaném autocvičišti nebo na trenažeru, následují jízdy v malém a poté ve středním a velkém provozu."),
                new IconListItem("Školení řidičů (profesní průkaz)", require('./components/icons/book.svg').default, 'Školení podle zákonu č. 247/2000 §47 a §48. Po absolvování je vydáno "Potvrzení o školení odborné způsobilosti řidičů. ', [{ link: require("./components/files/8rubrika.pdf"), name: "zadost_o_vycvik.pdf", text: "Žádost o přijetí do kurzu pravidelného školení" }]),
                new IconListItem("Výukové materiály", require('./components/icons/prof.svg').default, "Veškeré výukové materiály a ostatní informace získáte při první návštěvě nebo vyučování."),
              ]}
            />
          </div>
          <div className="final-exam">
            <img className="exam-icon" src={require('./components/icons/exam.svg').default} alt="exam" />
            <h2 className="exam-heading"><span className="half-bg-holder">Závěrečná zkouška<div className="half-bg"></div></span></h2>
            <p>Zkouška se skládá z těchto částí:</p>
            <table>
              <tbody>
                <tr>
                  <td><img src={require('./components/icons/handwriting.svg')} alt="handwriting" /></td>
                  <td>Přezkoušení z pravidel silničního provozu (test, délka trvání 30min.)</td>
                </tr>
                <tr>
                  <td><img src={require('./components/icons/settings.svg')} alt="settings" /></td>
                  <td>Přezkoušení z ovládání a údržby vozidla pouze u skupin C a D </td>
                </tr>
                <tr>
                  <td><img src={require('./components/icons/driving.svg')} alt="driving" /></td>
                  <td>Praktická jízda </td>
                </tr>
              </tbody>
            </table>
            <p>Poplatek za všechny tři zkoušky činí Kč 700,- a platí se před zahájením zkoušky na Městském úřadě. Zkouška z teorie se koná na Městském úřadě v Blansku nebo v Boskovicích. Zkouška z údržby a praktická zkouška se koná v budově autoškoly. Řidičské průkazy vydává příslušný městský nebo obecní úřad, na kterém je veden registr řidičů. Délka vyřízení řidičského průkazu je max. 20 kalendářních dnů.</p>
            <p>Zkoušky se konají v závislosti na počtu uchazečů, zhruba 1x týdně</p>
          </div>
        </div>




        {/* kontakt */}
        <div id="contacts">
          <div className="contact-info">
            <div className="heading">
              <h2><span className="half-bg-holder">Kontakt<div className="half-bg"></div></span></h2>
              <h3>Zaujali jsme vás? <br /> Napište nám.</h3>
            </div>
            <h3>ELKO AUTOŠKOLA spol. s.r.o.</h3>
            <div className="section">
              <table>
                <tbody>
                  <tr>
                    <td>
                      provozovna Blansko
                    </td>
                    <td>

                      <strong><Phone fontSize="small" className="text-icon" />&nbsp;606&nbsp;739&nbsp;410</strong>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            <div className="section">
              <table>
                <tbody>
                  <tr>
                    <td>
                      p. Jevický
                    </td>
                    <td>
                      <strong><PhoneAndroid fontSize="small" className="text-icon" />&nbsp;737&nbsp;381&nbsp;086</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      p. Kopřiva
                    </td>
                    <td>
                      <strong><PhoneAndroid fontSize="small" className="text-icon" />&nbsp;604&nbsp;377&nbsp;798</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="section">
              <strong><EmailOutlined fontSize="small" className="text-icon" />&nbsp;ojevicky@seznam.cz</strong>
            </div>

            <div className="section">
              <table>
                <tbody>
                  <tr>
                    <td>
                      jednatelé společnosti
                    </td>
                    <td>
                      <strong><Person fontSize="small" className="text-icon" />&nbsp;Osvald Jevický
                        <br /><Person fontSize="small" className="text-icon" />&nbsp;Zbyněk Kopřiva</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      sídlo společnosti
                    </td>
                    <td>
                      <strong><LocationOn fontSize="small" className="text-icon" />&nbsp;Pražská 5, 678&nbsp;01 <br /> Blansko</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="section">
              <table>
                <tbody>
                  <tr>
                    <td>
                      IČO
                    </td>
                    <td>
                      <strong>64507173</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      DIČ
                    </td>
                    <td>
                      <strong>CZ64507173</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div className="contact-form-holder">

            <div className="contact-form">
              <ContactForm />
            </div>
          </div>

        </div>
        <div id="footer">
          <div className="text">
            Vytvořeno: <br />
            Gabros Services 2019 © <br />
            <Link href="https://www.gabros.cz" color="primary">www.gabros.cz</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default App;